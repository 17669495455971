import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ProjectHome = () => {
  return (
    <LayoutComponent>
      <h3>Project Dashboard</h3>

      <div>
        <p>
          The Project Dashboard provides an overview of recent activity and
          projects. It allows users and admins to quickly access key information
          about their projects and the latest updates on test cases, test
          cycles, and issues.
        </p>
        <h5>Key Features</h5>
        <strong>Recent Projects</strong>:
        <ul>
          <li>
            Provides quick access to projects you are actively involved in.
          </li>
          <li>
            Highlights the most recently updated projects for better tracking of
            ongoing activities.
          </li>
        </ul>
        <strong>Activity in the Last 24 Hours</strong>:
        <ol>
          <li>Test cases added</li>
          <li>Test cycles executed</li>
          <li>Issues logged during test executions</li>
        </ol>
        <strong>Assigned for Execution</strong>:
        <ul>
          <li>Displays the test cases assigned to the user for execution.</li>
          <li>
            Users can access their assigned test cases and their respective test
            cycles assigned from here.
          </li>
        </ul>
        <strong>Assigned for Review</strong>:
        <ul>
          <li>Shows the test cases assigned to the user for review.</li>
          <li>
            Users can select and approve test cases directly from this section{" "}
          </li>
        </ul>
        <p>
          This dashboard serves as a quick snapshot of project activities and
          helps keep both users and administrators up to date with the latest
          testing progress and project developments.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default ProjectHome
